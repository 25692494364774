import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import SoldOutPage from '../soldOutPage';
import ComingSoonPage from '../comingSoonPage';
import ImageCarousel from '../../imageCarousel';

const RegisterPage = () => {

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Register.Title');
  }, []);

  if (featureToggles.soldOut()) {
    return <SoldOutPage title={t('Pages.Register.Title')} header={t('Pages.Register.Header')} />;
  }
  else if(!featureToggles.register()) {
    return <ComingSoonPage title={t('Pages.Register.Title')} header={t('Pages.Register.Header')} />;
  }

  return (
    <div className="con-container parent-grid">
      <div className="con-content left-box">
        <div className="con-flex__container headertext">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Register.Header')}</h1>
        </div>
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Register.TicketsForBeltech')} <a className="con-link__nounderline con-wrap"
            href="https://aisling-events.com/product/beltech-2025/" target="_blank"
            rel="noreferrer noopener">{t('Pages.Register.OnSaleHere')}</a>.
        </p>

        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Register.TicketOptions')}
        </p>

        <p className="con-text__xs--margin-bottom-1--bold ticket-name">{t('Pages.Register.Tickets.EarlySingleTicket')}: <a className="con-link__nounderline con-wrap"
          target="_blank"
          rel="noreferrer noopener"></a></p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.SingleTicketDescription')}</p>

        <p className="con-text__xs--margin-bottom-1--bold ticket-name">{t('Pages.Register.Tickets.EarlyBirdGroupTicket')}: <a className="con-link__nounderline con-wrap"
          target="_blank"
          rel="noreferrer noopener"></a></p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.PairTicketDescription')} </p>

        <p className="con-text__xs--margin-bottom-1--bold">
          {t('Pages.Register.EarlyBird')}
        </p>

        <p className="con-text__xs--margin-bottom-1--bold ticket-name">{t('Pages.Register.Tickets.SingleTicket')}: <a className="con-link__nounderline con-wrap"
          target="_blank"
          rel="noreferrer noopener"></a></p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.SingleTicketDescription')}</p>

        <p className="con-text__xs--margin-bottom-1--bold ticket-name">{t('Pages.Register.Tickets.PairTicket')}: <a className="con-link__nounderline con-wrap"
          target="_blank"
          rel="noreferrer noopener"></a></p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.PairTicketDescription')} </p>

        <div className="con-button__wrap">
          <a className="con-register__button" href="https://aisling-events.com/product/beltech-2025/">{t('Common.Tickets')}</a>
        </div>
      </div>
      <div className="con-content right-box">
        <ImageCarousel />
      </div>
    </div>
  );
};

export default RegisterPage;
