import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import comingSoon from '../../../assets/images/beltech_banner_coming_soon.webp';

const ComingSoonPage = (props) => {

  const { t } = useTranslation();
  let { title, header } = props;

  title = title || t('Pages.Schedule.Title');
  header = header || t('Pages.ComingSoon.Header');

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading">{header}</h1>
        </div>
        <img
          className="con-save-date-image"
          src={comingSoon}
          alt="Beltech 2025 is coming soon"
        />
      </div>
    </div>
  );
};

ComingSoonPage.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string
};

export default ComingSoonPage;
