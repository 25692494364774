import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import schedules from '../../../assets/static-data/2024/schedule_edu.json';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Schedule } from '@kainos-applied-innovation/convention-frontend-components/dist/components/schedule';

const EduSchedulePage = () => {

  const [eduScheduleList, setEDUSchedule] = useState(schedules.schedules);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.EDUSchedule.Title');

    if (!eduScheduleList) {
      setEDUSchedule(schedules);
    }
  }, []);

  if (featureToggles.schedule()) {
    return <ComingSoonPage title={t('Pages.EDUSchedule.Title')} header={t('Pages.EDUSchedule.Title') + ' - ' + t('Pages.ComingSoon.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.EDUSchedule.Header')}</h1>
        </div>
        <h3 className="con-heading__s--margin-bottom-1">{t('Pages.2024Schedule.VenueLocation')}</h3>
        <Schedule schedule={eduScheduleList} t={t}/>
      </div>
    </div>
  );
};

export default EduSchedulePage;
