import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import beltechCompetitionPage from '../../../assets/images/beltech_edu_2025.webp';

const EduConferencePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.BeltechEduConference.Title');
  }, []);

  if (!featureToggles.eduConference()) {
    return <ComingSoonPage header={t('Pages.BeltechEduConference.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <div className="con-flex__container--left">
            <h1 className="con-heading con-heading__l--margin-bottom-3">
              {t('Pages.BeltechEduConference.Header')}
            </h1>
          </div>
        </div>
        <img
          className="con-edu-conference-image"
          src={beltechCompetitionPage}
          alt="Beltech 2023 EDU Competition Page"
        />
      </div>
    </div>
  );
};

export default EduConferencePage;
